/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';

/* ::ng-deep .mat-expansion-panel:focus, .mat-expansion-panel:focus-within {
  background-color: white !important;
} */
:root {
  --scrollBgColor: white;
  --globeCardExpandText: white;
  --globeListText: black;
  --navbarBgColor: white;
  --arrow-color: #343a40;
}

body {
  --transparentInput: #e8edf300;
  --transparentIcon: #e8edf3;
  --text-primary: #174a87;
  --text-primary-inverted: #5fbf92;
  --primary-border: #17498759;
  --text-primary-light: rgba(23, 73, 135, 0.712);
  --tabBtnColor: white;
  --supportTabHeadingBG: #cfddeb;
  --supportTabContentBG: #f3f3f3;
  --txtDark: black;
  --progressContainer: #e8edf3;
  --newsCard: #e8edf3;
  --newsCardBorder: #e8edf3;
  --overlay: #ffffff;
  --testCardsbg: #bef1d9;
  --text-success: #5fbf92;
  --index-mob-card: #e8edf3;
  --datePicker: #e8edf3;
  --cardsBg: #f3f3f3;
  --cards-hover: #cbf3e4;
  --tab-bg: #eceef1;
  --tab-inactive: #292f35;
  --tab-active: #ffffff;
  --dialog-bg: #ffffff;
  --error-text: #d30015 !important;
  --input-color: #e8edf3;
  --input-color-alt: #ffffff;
  --qoutecard-bg: #e8edf3;
  --quoteDetailsCard: #f5f6fa;
  --cardsBg: #f3f3f3;
  --cards-hover: #cbf3e4;
  --border-white: #ffffff;
  --cardsBgLight: #e8edf3;

  --cardsBgWhite: #ffffff;

  --headerBorder: #c4cad4;
  --expiredFlag: #c2d4e9;
  --placeHolder-color: #a7b8cc;

  --person-icon: #7a8ba3;
  --nav-link-color: #000000;
  --filterbg: #f7f9fb;
  --loader-color: #f8ddb9;

  .primary-green-text {
    color: #5fbf92 !important;
  }

  .divider-2 {
    border-top: 1px solid var(--text-primary);
    margin: 0px 0;
    line-height: 1;
  }

  .text-muted {
    color: #212529bf !important;
  }

  .form-control {
    height: 56px !important;
  }
  textarea {
    color: black !important;
    background-color: #e8edf3 !important;
  }
  textarea::placeholder {
    color: #3f3f3f !important;
  }
  input {
    color: black !important;
  }

  .otp-input {
    margin-right: 0.6em !important;
    color: #000 !important;
    background-color: white !important;
  }
  .otp-input > input {
    color: #ff2424 !important;
  }
  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-color: #e8edf3 !important;
    color: black !important;
  }

  .feature-list {
    width: 100% !important;
  }
  .text-primary-dark {
    color: #1d2b3b !important;
  }
  .img-invert {
    filter: invert(0) !important;
  }
  .bg-primary {
    background-color: white !important;
  }

  :ng-deep .ng-select-container {
    background-color: #e8edf3 !important;
  }

  .hover-bg-blue:hover {
    background-color: #174a87 !important;
  }

  .hover-bg-blue:hover i {
    color: white !important;
  }

  .primary-bg {
    background-color: #174a87 !important;
  }
  .form-check-input {
    width: 16px;
    height: 16px;
  }

  .form-check-input:focus {
    border-color: var(--text-primary) !important;
    box-shadow: 0 0 0 0.2rem rgba(23, 74, 135, 0.25) !important;
  }
  .form-check-input:checked {
    background-color: var(--text-primary) !important;
    border-color: var(--text-primary) !important;
  }
  .darkMode-btn:hover {
    min-width: 8.5rem;
    background-color: #1d2b3b;
    color: white;
  }

  .darkMode-btn span {
    color: white;
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background: transparent !important;
  }

  .snackbar-success {
    background: #5fbf92;
    /* Change this to your desired color */
    color: white;
    border-radius: 5px;
    /* Optional: change text color */
  }

  .snackbar-error {
    background-color: #e45555;
    /* Change this to your desired color */
    color: white;
    /* Optional: change text color */
  }

  /* .mat-expansion-indicator::after {
    color: #174A87;
  } */
  .mat-expansion-indicator::after {
    color: #5fbf92 !important;
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: rgba(255, 255, 255, 0) !important;
  }

  background-color: white;
  color: black;
  transition: background-color 0.3s, color 0.3s;

  .globeaccord {
    background-color: #e8edf3 !important;
  }

  .globemap {
    background-color: #e8edf3 !important;
  }

  .repeating-text {
    background-color: #dceafb;
  }

  li {
    text-align: left;
    line-height: 32px;
    font-weight: 500;
    /* width: 100%; */
    /* background-color: #174A87; */
  }

  .innerDiv {
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.37) 29%,
      rgba(255, 255, 255, 0.938) 100%
    );
    border: 2px solid white;
    /* backdrop-filter: blur(2px); */
    margin-top: 12%;
  }

  /* .iinnerDiv::before {
    content: '';
    padding: 5% !important;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.37) 29%,
      rgba(255, 255, 255, 0.938) 100%
    );
    backdrop-filter: blur(2px);
    z-index: -1;
    border-radius: inherit;  To match border-radius if any 
  } */

  .mega-menu {
    background: #fff;
  }

  .bg-light {
    background-color: #e8edf3 !important;
  }

  .bg-dark {
    background-color: #174a87 !important;
  }

  .bg-green {
    background-color: #5fbf92;
  }

  .bg-light-marquee {
    background-color: #e8edf3 !important;
  }

  .btn-primary {
    background-color: #174a87 !important;
    color: white !important;
    width: 250px !important;
    height: 56px !important;
    border: none;
    padding: 0.5rem 1.25rem;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: font-size 0.6s, background-color 0.6s !important;
  }

  .btn-secondary {
    background-color: #174a87 !important;
    color: white !important;
    height: 56px !important;
    border: none;
    padding: 0.5rem 1.25rem;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: font-size 0.6s, background-color 0.6s !important;
  }

  .btn-primary:hover {
    font-size: 16px !important;
    font-weight: 500 !important;
    transition: font-size 0.6s, background-color 0.6s !important;
    background-color: #5fbf92 !important;
  }

  .btn-secondary:hover {
    font-size: 16px !important;
    font-weight: 500 !important;
    transition: font-size 0.6s, background-color 0.6s !important;
    background-color: #5fbf92 !important;
  }

  .btn-outline-primary {
    border: 1px solid #174a87 !important;
    color: #174a87 !important;
    height: 56px !important;
    /* padding: 4% 0 !important; */
    transition: font-size 0.6s, background-color 0.6s !important;
  }

  .btn-outline-primary:hover {
    font-size: 16px !important;
    /* font-weight: 500 !important; */
    transition: font-size 0.6s, background-color 0.6s !important;
    background-color: #174a87 !important;
    color: white !important;
  }

  .btn-outline-secondary {
    border: 1px solid #174a87 !important;
    color: #174a87 !important;
    /* height: 56px !important; */
    /* padding: 4% 0 !important; */
    transition: font-size 0.6s, background-color 0.6s !important;
  }

  .btn-outline-secondary:hover {
    font-size: 16px !important;
    /* font-weight: 500 !important; */
    transition: font-size 0.6s, background-color 0.6s !important;
    background-color: #174a87 !important;
    color: white !important;
  }

  .btn-success {
    background-color: #5fbf92;
    color: white;
  }

  .btn-success:hover {
    background-color: #5fbf92;
  }

  .btn-outline-success {
    border: 1px solid #5fbf92;
    color: white;
  }

  .btn-outline-success:hover {
    background-color: #5fbf92;
  }

  .text-primary {
    color: #174a87 !important;
  }

  .text-success {
    color: #5fbf92 !important;
  }

  .mega-menu-column li a {
    text-decoration: none;
    color: #000;
  }

  .nav-link {
    color: #000 !important;
  }

  /* subscription.component.css */
  /* .subscribe-container {
    padding: 20px;
    text-align: center;
    background-color: #E8EDF3;
    border-radius: 5px;
    margin-bottom: 5% !important;
  }

  .subscribe-container input {
    display: block !important;
    width: 100% !important;
    margin-bottom: 1rem !important;
  }

  .subscribe-container button {
    display: block !important;
    width: 100% !important;
  } 

  .form-inline input[type="email"] {
    width: 100%;
  }*/

  .cookie-consent-banner {
    background-color: white;
    border-top: 1px solid #ddd;
  }

  /* Mid Screen */
  @media (min-width: 768px) {
    /* .subscribe-container h1 {
      font-weight: 500;
      font-size: 42px;
    }

    .subscribe-container {
      padding: 4%;
    }

    .subscribe-container input {
      height: 56px !important;
      width: 50vh !important;
    }


    .subscribe-container button {
      width: fit-content !important;
      padding: 0 25px !important;
    }

    .form-inline {
      display: flex;
      align-items: center;
    }

    .form-inline input[type="email"] {
      width: 100%;
    } */
  }

  /* Laptop */
  @media (min-width: 1280px) {
    .innerDiv {
      margin-top: 2%;
    }

    /* .subscribe-container h1 {
      font-weight: 600;
      font-size: 32px;
    }

    .subscribe-container input {
      height: 56px !important;
      width: 50vh !important;
    }

    .subscribe-container button {
      width: fit-content !important;
      padding: 0 25px !important;
    } */
  }

  /* Desktop */
  @media (min-width: 1920px) {
    /* .subscribe-container h1 {
      font-weight: 600;
      font-size: 42px;
    }

    .subscribe-container input {
      height: 56px !important;
      width: 50vh !important;
    }

    .subscribe-container button {
      width: fit-content !important;
      padding: 0 25px !important;
    } */
  }
}

body.dark-mode {
  --text-primary: #5fbf92;
  --text-primary-light: #5fbf92;
  --tabBtnColor: #000;
  --txtDark: white;
  --datePicker: #1d2b3b;
  --text-success: #fff;
  --text-primary-inverted: #174a87;

  /* vars  */
  --globeListText: white;
  --scrollBgColor: #1d2b3b;
  --navbarBgColor: #1d2b3b;
  --progressContainer: #1d2b3b;
  --supportTabHeadingBG: #174a87;
  --supportTabContentBG: #1d2b3b;
  --newsCard: transparent;
  --newsCardBorder: #5fbf92;
  --primary-border: #5fbf9259;
  --testCardsbg: transparent;
  --index-mob-card: #bbf1dc;
  --cardsBg: #2d435b;
  --cards-hover: #2d435b50;
  --tab-bg: #4e6888;
  --tab-inactive: #a1b9d6;
  --tab-active: #ffffff;
  --dialog-bg: #2d435b;
  --error-text: #ff5969;
  --input-color: #2c3f54;
  --input-color-alt: #2c3f54;
  --qoutecard-bg: #2d435b;
  --quoteDetailsCard: #2d435b;
  --cardsBg: #2d435b;
  --cards-hover: #2d435b50;
  --border-white: #5fbf92;
  --cardsBgLight: #3a526d;

  --cardsBgWhite: #2d435b;

  --transparentInput: #2c3f54;
  --transparentIcon: #5fbf9200;
  --headerBorder: #c4cad4;
  --expiredFlag: #1d2b3b;
  --person-icon: #1d2b3b;
  --nav-link-color: #d5d5d4;
  --placeHolder-color: #d9d8d6;

  --loader-color: #5fbf92;
  --filterbg: #2c3f54;
  .primary-green-text {
    color: #5fbf92 !important;
  }
  .text-muted {
    color: #dbdbdb !important;
  }

  .primary-bg {
    background-color: #5fbf92 !important;
  }
  .form-select {
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-color: transparent !important;
    color: white !important;
  }
  .navbar-toggler-icon {
    color: white !important;
  }

  :ng-deep .ng-select-container {
    background-color: #2c3f54 !important;
  }
  textarea {
    color: white !important;
    background-color: #2c3f54 !important;
  }
  textarea::placeholder {
    color: lightgray !important;
  }
  input {
    color: white !important;
  }

  input::-webkit-input-placeholder {
    /* Chrome, Safari, Edge */
    color: lightgray !important;
    opacity: 1;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: lightgray !important;
    opacity: 1;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: lightgray !important;
    opacity: 1;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: lightgray !important;
    opacity: 1;
  }
  .otp-input {
    margin-right: 0.6em !important;
    color: #ffffff !important;
    background-color: #2c3f54 !important;
  }
  .otp-input > input {
    color: #ffffff !important;
  }
  background-color: #1d2b3b;
  color: white !important;

  .img-invert {
    filter: invert(1) !important;
  }
  .text-dark {
    color: white !important;
  }

  .text-primary-dark {
    color: #5fbf92 !important;
  }

  .hover-bg-blue:hover {
    background-color: #5fbf92 !important;
  }

  .bg-primary {
    background-color: #1d2b3b !important;
  }

  /* --globeCardExpandText: black; */
  .innerDiv {
    background: linear-gradient(
      75deg,
      #1d2b3b 1.34%,
      rgba(29, 43, 59, 0.29) 100.6%
    );
    border: 2px solid #89a3c0;
  }

  .panel {
    background-color: #1d2b3b;
    color: white;
  }

  .globeaccord {
    background-color: #cadfd5 !important;
  }

  .globemap {
    background-color: #cadfd5 !important;
  }

  .repeating-text {
    background-color: #0a6b3e;
  }

  ::ng-deep .inputDarkMode {
    background: linear-gradient(
      75deg,
      #1d2b3b 1.34%,
      rgba(29, 43, 59, 0.29) 100.6%
    ) !important;
  }

  .mega-menu {
    background: #1d2b3b;
  }

  .btn-primary {
    background-color: #5fbf92 !important;
    /* font-weight: 500 !important; */
    color: #1d2b3b !important;
  }

  .btn-primary:hover {
    font-size: 16px !important;
    /* font-weight: 500 !important; */
    transition: font-size 0.6s, background-color 0.6s !important;
  }

  .btn-outline-primary {
    border: 1px solid #5fbf92 !important;
    color: white !important;
    /* padding: 4% 0 !important; */
    /* transition: font-size 0.6s, background-color 0.6s !important; */
  }

  .btn-outline-primary:hover {
    /* font-size: 16px !important; */
    /* transition: font-size 0.6s, background-color 0.6s !important; */
    background-color: #5fbf92 !important;
    color: #1d2b3b !important;
  }

  .btn-secondary {
    background-color: #5fbf92 !important;
    color: #1d2b3b !important;
  }

  .btn-secondary:hover {
    background-color: #174a87 !important;
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    transition: font-size 0.6s, background-color 0.6s !important;
  }

  .btn-outline-secondary {
    /* font-size: 16px !important; */
    /* transition: font-size 0.6s, background-color 0.6s !important; */
    border: 1px solid #5fbf92 !important;
    color: white !important;
  }

  .btn-outline-seondary:hover {
    /* font-size: 16px !important; */
    /* transition: font-size 0.6s, background-color 0.6s !important; */
    border: 1px solid #5fbf92 !important;
    background-color: #5fbf92 !important;
    color: #1d2b3b !important;
  }

  li a {
    color: white;
  }

  .bg-light {
    background-color: #1d2b3b !important;
  }

  .bg-dark {
    background-color: #1d2b3b !important;
  }

  .bg-light-marquee {
    background-color: #174a87 !important;
  }

  .text-primary {
    color: #5fbf92 !important;
  }

  .container-fluid {
    /* border-top: 1px solid black; */
  }

  .text-white {
    color: #5fbf92 !important;
    font-weight: 600;
  }

  .nav-link {
    color: #d5d5d4 !important;
  }

  .subscribe-container {
    border-top: 1px solid #000;
    background-color: #1d2b3b !important;
  }

  .cookie-consent-banner {
    background-color: #1d2b3b;
    border-top: 1px solid #1d2b3b;
  }

  .btn-outline-success {
    /* border: 1px solid #5FBF92; */
    color: white;
  }

  .darkMode-btn:hover {
    min-width: 8.5rem;
    background-color: #fff;
  }

  .darkMode-btn span {
    color: #1d2b3b;
  }

  .card {
    background-color: #1d2b3b;
  }

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  .emailContainer {
    background-color: #1d2b3b;
  }

  .iti .dropdown-menu.country-dropdown {
    background-color: #2d435b !important;
  }

  .iti .iti__country-list {
    background-color: #2d435b !important;
    color: white !important;
  }
}

* {
  font-family: 'Poppins', sans-serif !important;
}

img {
  object-fit: cover !important;
  object-position: center !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 16px solid #174a87;
  /* Light grey */
  border-top: 16px solid #5fbf92;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* /// STYLES FOR COUNTRY PHONE NUMBER DROP DOWN  */

.wrapper {
  margin-bottom: 0px;
}

.iti {
  display: flex !important;
  margin-bottom: 0px;
  width: 100% !important;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  background-color: var(--input-color-alt) !important;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.iti__flag {
  background-image: url(/assets/flags/flags.png) !important;
}
.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input {
  display: flex;
  flex-direction: row;
}
ngx-intl-tel-input input {
  height: 90%;
  margin-bottom: 20px;
  padding: 1% 10% 1% 10%;
  width: 100%;
  border-radius: 4px;
  font-size: 18px;
  border-top: 1px solid #c7cace;
  border-right: 1px solid #c7cace;
  border-bottom: 1px solid #c7cace;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none; /* Remove left border */
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
  padding-left: 6px;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
  padding-left: 6px;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
  padding-left: 6px;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
  padding-left: 6px;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input {
  padding-left: 24px !important;
  background-color: var(--input-color-alt) !important;
}

.iti * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iti__country-list {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.iti__flag-container {
  position: relative; /* For positioning pseudo-element */
  height: 90%;
  border-radius: 4px;
  border-top: 1px solid #c7cace;
  border-left: 1px solid #c7cace;
  border-bottom: 1px solid #c7cace;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none; /* Remove left border */
  cursor: pointer;
  background-color: var(--input-color-alt) !important;
}

.iti__flag-container::after {
  content: '';
  position: absolute;
  top: 24%; /* Adjust this to control the start position of the border */
  right: 10%; /* Position the border on the right */
  width: 1px; /* Control the thickness of the vertical border */
  height: 60%; /* Adjust this value to control the height of the border */
  background-color: #c7cace; /* Border color */
}
#country-search-box {
  width: 100% !important;
  background-color: var(--input-color-alt) !important;
  margin-bottom: 0px !important;
}

.mat-sort-header-content {
  font-size: 14px !important;
}

/* .iti input {
  width: 28.5rem !important;
} */

::ng-deep .mat-step-icon-state-edit {
  background-color: yellow !important;
}

::ng-deep .mat-step-icon-state-edit > .mat-step-icon > .mat-icon {
  display: none !important;
}
